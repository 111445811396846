import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title }) {
  const { contentfulGeneralSettings } = useStaticQuery(
    graphql`
      query DefaultSEOQuery {
        contentfulGeneralSettings(
          id: { eq: "a0d4505b-b555-551d-bad7-f565b4d40fe2" }
        ) {
          id
          title
          metaTitle
          metaKeywords
          metaDescription
          canonicalUrl
          ogImage {
            resize(width: 1200, height: 627, resizingBehavior: FILL) {
              src
            }
          }
        }
      }
    `
  )

  const metaDescription =
    description || contentfulGeneralSettings.metaDescription

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${contentfulGeneralSettings.metaTitle}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `url`,
          content: contentfulGeneralSettings.canonicalUrl,
        },
        {
          name: `og:url`,
          content: contentfulGeneralSettings.canonicalUrl,
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `keywords`,
          content: contentfulGeneralSettings.metaKeywords || "",
        },
        {
          name: `og:image`,
          content: contentfulGeneralSettings.ogImage.resize.src || "",
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: "@midnightbloom",
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
